<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-body>
            <b-tabs>
              <b-tab
                title="Học bổng"
                active
              >
                <vue-good-table
                  :columns="scholarshipColumns"
                  :rows="curriculumVitaeScholarships"
                  :pagination-options="paginationOptions"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-tab>
              <b-tab title="Khen thưởng">
                <vue-good-table
                  :columns="awardColumns"
                  :rows="curriculumVitaeAwards"
                  :pagination-options="paginationOptions"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-tab>
              <b-tab title="Kỷ luật">
                <vue-good-table
                  :columns="disciplineColumns"
                  :rows="curriculumVitaeDisciplines"
                  :pagination-options="paginationOptions"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-tab>
            </b-tabs>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCol, BFormSelect, BOverlay, BPagination, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'CurriculumVitaeScholarshipAwardDiscipline',
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BFormSelect,
    BOverlay,
    BPagination,
    BTabs,
    BTab,
    VueGoodTable,
  },
  props: {
    curriculumVitaeScholarships: {
      type: Array,
      default: () => [],
    },
    curriculumVitaeAwards: {
      type: Array,
      default: () => [],
    },
    curriculumVitaeDisciplines: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      scholarshipColumns: [
        {
          label: 'Khóa học',
          field: 'course',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập khóa học',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập học kỳ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên học bổng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học bổng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      awardColumns: [
        {
          label: 'Tên khen thưởng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên khen thưởng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      disciplineColumns: [
        {
          label: 'Tên kỷ luật',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày kỷ luật',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
